import logoDynomo from '../assets/svg/logo.svg'
import techLogin from '../assets/svg/techLoginImage.svg'
import cat1 from '../assets/png/cat1.png'
import cat2 from '../assets/png/cat2.png'
import cat3 from '../assets/png/cat3.png'
import cat4 from '../assets/png/cat4.png'
import cat5 from '../assets/png/cat5.png'
import cat6 from '../assets/png/cat6.png'
import cat7 from '../assets/png/cat7.png'
import cat8 from '../assets/png/cat8.png'
import cat9 from '../assets/png/cat9.png'
import cat10 from '../assets/png/cat10.png'
import cat11 from '../assets/png/cat11.png'
import cat12 from '../assets/png/cat12.png'
import cat13 from '../assets/png/cat13.png'
import cat14 from '../assets/png/cat14.png'
import cat15 from '../assets/png/cat15.png'
import location from '../assets/svg/location.svg'
import menuicon from '../assets/svg/menuicon.svg'
import searchbar from '../assets/svg/searchbar.svg'
import favourite from '../assets/svg/favourite.svg'
import notification from '../assets/svg/notification.svg'
import avatarman from '../assets/svg/avatarman.svg'
import cart from '../assets/svg/cart.svg'
import searchLogin from '../assets/svg/searchLogin.svg'
import arrowdown from '../assets/svg/arrow-down.svg'
import arrowleft from '../assets/svg/leftarrow.svg'
import arrowright from '../assets/svg/rightarrow.svg'
import logolight from '../assets/png/logolight.png'
import qrcode from '../assets/png/qrcode.png'
import appstore from '../assets/png/downloadappstore.png'
import playstore from '../assets/png/downloadplaystore.png'
import facebook from '../assets/svg/Icon-Facebook.svg'
import linkedin from '../assets/svg/Icon-Linkedin.svg'
import instagram from '../assets/svg/icon-instagram.svg'
import twitter from '../assets/svg/icontwitter.svg'
import shoppingwomen from '../assets/png/shoppingwomen.png'
import product1 from '../assets/png/product1.png'
import product2 from '../assets/png/product2.png'
import product3 from '../assets/png/product3.png'
import product4 from '../assets/png/product4.png'
import item1 from '../assets/png/item1.png'
import item2 from '../assets/png/item2.png'
import item3 from '../assets/png/item3.png'
import item4 from '../assets/png/item4.png'
import item5 from '../assets/png/item5.png'
import newsLetter1 from '../assets/png/newsletter1.png'
import newsLetter2 from '../assets/png/newslettercoffee.png'
import clock from '../assets/svg/clock.svg'
import plus from '../assets/svg/Plus.svg'
import minus from '../assets/svg/Minus.svg'
import star from '../assets/svg/star.svg'
import user from '../assets/svg/reviewclient.svg'
import close from '../assets/png/close.png'
import location2 from '../assets/svg/locationwithbg.svg'
import shipping from '../assets/svg/Shipping.svg'
import payment from '../assets/svg/payment.svg'
import edit from '../assets/png/edit.png'
import add from '../assets/svg/addlight.svg'
import visa from '../assets/svg/visa.svg'
import heart from '../assets/svg/Heart.svg'
import master_cart from '../assets/svg/master-card.svg'
import ideal from '../assets/svg/ideal.svg'
import mastercard from '../assets/svg/mastercard.svg'
import question from '../assets/svg/question.svg'
import chip from '../assets/png/chip.png'
import cardline from '../assets/svg/cardline.svg'
import arrowRight from '../assets/svg/arrowright.svg'
import pluscircle from '../assets/svg/pluscircle.svg'
import wifi from '../assets/svg/wifi.svg'
import product5 from '../assets/png/product5.png'
import credit_card from '../assets/svg/credit_card.svg'
import servicesIcon from '../assets/svg/serviceicon.svg'
import messageicon from '../assets/svg/chaticon.svg'
import ordericon from '../assets/svg/ordericon.svg'
import feedicon from '../assets/svg/feedicon.svg'
import feedDark from '../assets/svg/feeddark.svg'
import orderdark from '../assets/svg/orderdark.svg'
import servicedark from '../assets/svg/servicedark.svg'
import messagedark from '../assets/svg/chatdark.svg'
import eye from '../assets/svg/eye.svg'
import preview from '../assets/svg/preview.svg'
import trash from '../assets/svg/trash.svg'
import edit2 from '../assets/svg/edit.svg'
import filter from '../assets/svg/filter.svg'
import camera from '../assets/svg/Camera.svg'
import food1 from '../assets/png/food1.png'
import subscription from '../assets/svg/subscirption.svg'
import logout from '../assets/svg/Logout.svg'
import bellnotify from '../assets/svg/bellnotify.svg'
import language from '../assets/svg/language.svg'
import promotion from '../assets/svg/promotion.svg'
import gift from '../assets/svg/gift.svg'
import changePassword from '../assets/svg/changepassword.svg'
import openingtime from '../assets/png/openinigtime.png'
import help from '../assets/svg/gethelp.svg'
import arrowrightgray from '../assets/svg/arrowrightgray.svg'
import penciledit from '../assets/svg/penciledit.svg'
import phone from '../assets/svg/Phone.svg'
import arrowleft2 from '../assets/svg/arrowleft2.svg'
import arrowright2 from '../assets/svg/arrowright2.svg'
import closeaccount from '../assets/svg/closeaccount.svg'
import locationaccount from '../assets/svg/locationaccount.svg'
import happyemoji from '../assets/svg/happyemoji.svg'
import fileclip from '../assets/svg/clipfile.svg'
import arrowup from '../assets/svg/arrow-up.svg'
import accountlight from '../assets/svg/accountlight.svg'
import accountdark from '../assets/svg/acountdark.svg'
import searchnormal from '../assets/svg/search-normal.svg'
import locationdark from '../assets/svg/Locationmarker.svg'
import information from '../assets/svg/Information.svg'
import information2 from '../assets/svg/information2.svg'
import eyeoff from '../assets/svg/Eyeoff.svg'
import bronze from '../assets/png/bronze.png'
import silver from '../assets/png/silver.png'
import gold from '../assets/png/gold.png'
import platinum from '../assets/png/platinum.png'
import imagecloud from '../assets/svg/uploadcloud.svg'
import applelogo from '../assets/svg/apple.svg'
import google from '../assets/svg/Google.svg'
import earning from '../assets/png/earning (2).png'
import bag from '../assets/png/order.png'
import adsIcon from '../assets/png/ads.png'
import subscriptionIcon from '../assets/png/Subscription.png'
import storeIcon from '../assets/png/store.png'
import ready from '../assets/png/Ready.png'
import minuss from '../assets/svg/minuss.svg'
import storelight from '../assets/svg/storelight.svg'
import storedark from '../assets/svg/storedark.svg'
import adslight from '../assets/svg/adslight.svg'
import adsdark from '../assets/svg/adsdark.svg'
import folder from '../assets/svg/folder.svg'
import mapPin from '../assets/svg/map-pin.svg'


import finabeeoutline from '../assets/svg/finabeeoutline.svg'
import finabeelight from '../assets/svg/finabeelight.svg'
import finabeewithline from '../assets/png/finebeee.png'
import dashboardlight from '../assets/svg/dashboardlight.svg'
import dashboarddark from '../assets/svg/dashboarddark.svg'
import coursedark from '../assets/svg/coursedark.svg'
import courselight from '../assets/svg/courselight.svg'
import childlight from '../assets/svg/childlight.svg'
import childdark from '../assets/svg/childdark.svg'
import parentlight from '../assets/svg/parentslight.svg'
import parentdark from '../assets/svg/parentsdark.svg'
import quizlight from '../assets/png/quizlight.png'
import quizdark from '../assets/svg/quizdark.svg'
import settingdark from '../assets/svg/settingdark.svg'
import finabeesingle from '../assets/svg/finabeesingle.svg'
import arrowdownlight from '../assets/svg/arrowdownlight.svg'
import course1 from '../assets/png/course1.png'
import course2 from '../assets/png/course2.png'
import course3 from '../assets/png/course3.png'
import course4 from '../assets/png/course4.png'
import course5 from '../assets/png/course5.png'
import news1 from '../assets/png/news1.png'
import fileavatar from '../assets/svg/fileavatar.svg'
import testpdf from '../assets/png/testpdf.pdf'
import honeypot from '../assets/svg/honeypot.svg'
import pdflogo from '../assets/png/pdflogo.png'
import avatar1 from '../assets/png/avatar1.png'

import tidelogo from '../assets/png/tidelogo.png'
import tidelogo2 from '../assets/png/tidelogo2.png'
import service1 from '../assets/png/service1.png'
import profileavatar from '../assets/png/profileavatar.jpg'
import gamerlogo from '../assets/png/gamerlogo.png'
export {
    gamerlogo, profileavatar, service1, tidelogo2, tidelogo, avatar1, pdflogo, honeypot, testpdf, news1, fileavatar, course2, course3, course4, course5, course1, finabeesingle, arrowdownlight, finabeelight, settingdark, dashboarddark, dashboardlight, coursedark, courselight, quizdark, quizlight, parentdark, parentlight, childdark, childlight,
    finabeewithline, finabeeoutline, location, mapPin, subscriptionIcon, adsIcon, storeIcon, folder, edit2, information2, adsdark, adslight, storedark, storelight, bag, ready, minuss, earning, applelogo, google, platinum, silver, gold, bronze, imagecloud, happyemoji, eyeoff, arrowup, closeaccount, arrowleft2, arrowright2, information, locationaccount, subscription, logout, bellnotify, language, promotion, gift, changePassword, help, openingtime, arrowrightgray, penciledit, fileclip, food1, phone, accountdark, accountlight, searchnormal, locationdark, camera, filter, trash, preview, arrowRight, eye, messagedark, servicedark, orderdark, feedDark, servicesIcon, messageicon, ordericon, feedicon, product5, searchLogin, pluscircle, heart, credit_card, question, cardline, chip, wifi, mastercard, master_cart, visa, ideal, edit, add, location2, payment, shipping, close, user, plus, star, minus, clock, newsLetter2, arrowleft, arrowright, item2, item1, newsLetter1, item3, item4, item5, product1, product2, product3, product4, qrcode, shoppingwomen, facebook, linkedin, instagram, twitter, appstore, playstore, logolight, searchbar, favourite, notification, avatarman, cart, arrowdown, menuicon, techLogin, logoDynomo, cat1, cat2, cat3, cat4, cat5, cat6, cat7, cat8, cat9, cat10, cat11, cat12, cat13, cat14, cat15
}
